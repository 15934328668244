import { useFormContext } from "react-hook-form";


export default function PartE() {
    const { register } = useFormContext();

  return (<>
      <h4>Part E: Video Recording Policy</h4>
      <p>
        As part of our shooting range package, we offer complimentary video recordings of your experience. These recordings are provided as an additional service through a third-party system, and while we strive to ensure a quality experience, we cannot guarantee the final quality or completeness of the videos. Issues such as poor video quality, missing frames, or incomplete footage may occasionally occur and are outside of our control. By signing this waiver, you acknowledge and agree that Wild West Indoor Range LTD. dba/ Wild West Shooting Centre is not liable for any issues related to the video recording service, as it is provided by a third-party company and not directly managed by us.
      </p>
      <input
                className="mb-3 floating form-text"
                required
                type="checkbox"
                placeholder="Recording Policy"
                {...register("chkPartE")}
            />
        <p>
          Confirm you have read and understood Part E
        </p>



    </>)
}
